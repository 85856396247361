import './App.css';
import { TypeAnimation } from 'react-type-animation';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <TypeAnimation
        preRenderFirstString={false}
        sequence={[
            'bchards',
            2000,
            "",
            2000,
            'bchards',
          ]}
          wrapper="span"
          cursor={false}
          repeat={Infinity}
          style={{ whiteSpace: 'pre-line', fontSize: '2em', display: 'block' }}
        />
      </header>
    </div>
  );
}

export default App;
